<template>
  <DsPageFooter>
    <template #primaryNavigation>
      <DsFooterPrimaryNavigation v-if="variant === 'primaryNavigation' && footerItems && footerItems.items.primaryItems"
        :items="footerItems.items.primaryItems" :screen="screen">
        <template #socialBar>
          <DsSocialBar v-if="socialBarItems" :items="socialBarItems" theme="dark" disposition="horizontal" is-footer />
        </template>
        <template #signup>
          <DsLink v-if="newsletterSignUp" :content="newsletterSignUp.title" :url="newsletterSignUp.url"
            :open-in-new-window="newsletterSignUp.openInNewWindow" :nuxt="newsletterSignUp.nuxt" type="button"
            variant="secondary" />
        </template>
      </DsFooterPrimaryNavigation>
      <p v-if="variant === 'homepageNavigation'" class="ds-color-white">
        {{ labels.goTo }}
        <DsLink :content="labels.brandDomain || ''" type="link" color="white" variant="secondary" :url="mainSiteUrl" />
      </p>
    </template>
    <template v-if="footerItems && footerItems.items.secondaryItems" #secondaryNavigation>
      <DsFooterSecondaryNavigation :items="footerItems.items.secondaryItems" />
    </template>
    <template #localeSwitcher>
      <DsDropdown v-show="localeOptions.length > 0" theme="light" :label="labels.languageSwitcher" :text="currentLocale"
        :icon="{ name: 'globe', category: 'various' }" align-y="top" :align-x="dropdownAlignX" :options="localeOptions"
        height="260" :with-bold-text="false" @dropdown-item-clicked="handleDropdownItemClicked" />
    </template>
  </DsPageFooter>
</template>

<script setup>
import {
  DsDropdown,
  DsFooterPrimaryNavigation,
  DsFooterSecondaryNavigation,
  DsLink,
  DsPageFooter,
  DsSocialBar
} from 'awds'
import { useLocales } from '../../utils/useLocales'

const { isScreenSmall, screen } = useScreen()
const { locale } = useI18n()
const { locales } = useLocales(useRuntimeConfig().public.targetSite)
const { labels } = useGlobalData()
const mainSiteUrl = useRuntimeConfig().public.mainSiteUrl
const { i18nParams } = useI18nPageInfo()
const switchLocalePath = useSwitchLocalePath()

const props = defineProps({
  variant: {
    type: String,
    default: 'primaryNavigation'
  },
  footerData: {
    type: Object,
    default: null
  }
})

const footerItems = computed(() => props.footerData)

const socialBarItems = computed(() => {
  return footerItems.value.items?.socialItems
})

const newsletterSignUp = computed(() => {
  return footerItems.value.items?.newsletterSignUp
})

const currentLocale = computed(() => {
  const _locale = locales.find(loc => loc.code === locale.value) || locale.value
  return `${_locale.country} (${_locale.language})`
})

const localeOptions = computed(() => {
  // Get page locales
  const pageLocales = i18nParams.value
    ? locales.reduce((acc, locale) => {
      const slug = i18nParams.value[locale.code]
      if (slug && Object.keys(slug).length !== 0) {
        acc.push({
          value: locale.code,
          text: `${locale.country} (${locale.language})`
        })
      }
      return acc
    }, [])
    : []

  // all page locales
  const allLocales = locales.reduce((acc, locale) => {
    acc.push({
      value: locale.code,
      text: `${locale.country} (${locale.language})`
    })
    return acc
  }, [])

  // on preview all locales
  if (useRuntimeConfig().public.preview && process.client) {
    return allLocales
  }

  // return all locales when there is more than 1 slug with a locale
  if (pageLocales?.length > 1) {
    return pageLocales
  }

  return allLocales
})

const dropdownAlignX = computed(() => {
  return isScreenSmall.value ? 'left' : 'right'
})

const handleDropdownItemClicked = ({ value }) => {
  const path = switchLocalePath(value)
  if (process.env.preview && process.client) {
    // eslint-disable-next-line no-console
    console.info('switchLocalePath', value, path)
  }
  // Patch to add tailing slash to localized home pages, required by SEO for compatibility with old implementation
  const isLocaleHome = /^\/[a-z]{2}_[A-Z]{2}$/.test(path)
  const localePath = isLocaleHome ? `${path}/` : path
  // As the OneTrust cookie related script has to be reloaded
  navigateTo(localePath, { replace: true, external: true })
}

</script>
